import "./RoadMap.scss";

import { LazyLoadComponent } from "react-lazy-load-image-component";

function RoadMap() {
  return (
      <div className={"roadmap"} id={"roadmap"}>
        <h2>THE DARK COUNTRY ROADMAP</h2>
        <p>
          We are a team of dedicated gamers and devs working hard to make our
          vision of game that will satisfy both needs of gamers and crypto-fans.
        </p>
      <LazyLoadComponent threshold={1000}>
        <div className={"roadmap-container"}>
          <div className={"road-wrapper zero-box"}>
            <div>
              <span>Q4'19</span>
              <span>Pre-sale launch, Initial Marketing Campaign</span>
            </div>
          </div>
          <div className={"road-wrapper first-box"}>
            <div>
              <span>Q2'20</span>
              <span>
                Packs opening event, Public beta launch of Dark Country
              </span>
            </div>
          </div>
          <div className={"road-wrapper second-box"}>
            <div>
              <span>Q3'20</span>
              <span>Game expansion pack 1</span>
            </div>
          </div>
          <div className={"road-wrapper third-box"}>
            <div>
              <div>
                <span>Q4'20</span>
                <span>Standard Packs Opening, Early Access Game</span>
              </div>
            </div>
          </div>
          <div className={"road-wrapper fourth-box"}>
            <div>
              <span>Q2'21</span>
              <span>DC Lands Introduction and Sale,</span>
              <span>Major game updates</span>
            </div>
          </div>
          <div className={"road-wrapper fifth-box"}>
            <div>
              <span>Q3'21</span>
              <span>Lands Alpha gameplay launch,</span>
              <span>New Dark Country game mode</span>
            </div>
          </div>
          <div className={"road-wrapper sixth-box"}>
            <div>
              <span>Q4'21</span>
              <span>DC roguelike campaigns launch</span>
            </div>
          </div>
          <div className={"road-wrapper seventh-box"}>
            <div>
              <span>Q1'22</span>
              <span>Full Dark Country game release</span>
            </div>
          </div>
        </div>
      </LazyLoadComponent>
      </div>
  );
}

export default RoadMap;
