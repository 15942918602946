import "./Header.scss";

import { slide as Menu } from "react-burger-menu";
import React from "react";
import { Link } from "react-scroll";

function Header() {
  return (
    <div className={"header-wrapper"}>
      <div className={"header"}>
        <span>
          <Link to='packs' smooth={true} duration={1000} href={""}>Packs</Link>
        </span>
        <span>
          <Link to="whitelist" smooth={true} href={""} duration={2000}>
            Whitelist
          </Link>
        </span>
        <span>
          <Link to="about" smooth={true} href={""} duration={3000}>
            About DC
          </Link>
        </span>
        <span>
          <a href={"#lands"}>Lands</a>
        </span>
        <span>
          <a href={"#vampires"}>Vampires</a>
        </span>
        <span>
          <a href={"#unpacking"}>Unpacking</a>
        </span>
        <span>
          <a href={"#roadmap"}>Roadmap</a>
        </span>
        <span>
          <a href={"#promo"}>Promo</a>
        </span>
        <span>
          <a href={"#contact"}>Contact</a>
        </span>
      </div>
      <Example />
    </div>
  );
}

class Example extends React.Component {
  showSettings(event) {
    event.preventDefault();
  }

  render() {
    // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling
    return (
      <div className={"header-burger"}>
        <Menu>
          <span>
            {/*<Link>Packs</Link>*/}
            <a href="#packs">Packs</a>
          </span>
          <span>
            <a href="#whitelist">Whitelist</a>
          </span>
          <span>
            <a href="#about">About DC</a>
          </span>
          <span>
            <a href="#lands">Lands</a>
          </span>
          <span>
            <a href="#vampires">Vampires</a>
          </span>
          <span>
            <a href="#unpacking">Unpacking</a>
          </span>
          <span>
            <a href="#roadmap">Roadmap</a>
          </span>
          <span>
            <a href="#promo">Promo</a>
          </span>
          <span>
            <a href="#contact">Contact</a>
          </span>
        </Menu>
      </div>
    );
  }
}

export default Header;
