import React from "react";
import { Link } from "react-router-dom";

import Rodal from "rodal";
import "rodal/lib/rodal.css";

import CustomButton from "../../components/Shared/CustomButton/CustomButton";

import './PurchaseCongratulationModal.scss';

export default function PurchaseCongratulationModal({ visible, onClose }) {
    return (
        <Rodal
            visible={visible}
            onClose={onClose}
            showCloseButton={true}
            className="purchase-congratulation"
            animation="slideUp"
            duration={800}
            closeOnEsc={true}
        >
            <div className="purchase-congratulation-modal-wrapper">
                <p>CONGRATULATIONS!</p>
                <p>Your purchase is complete!</p>
                <p>We will notify you via email on when you can see you Land packs on our Dark Country site.</p>
                <CustomButton
                    text={<Link to="/my-items">{ 'My Items' }</Link>}
                />
            </div>
        </Rodal>
    );
}
