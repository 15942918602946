import React from "react";

import './Lands.scss';

import {
    COMMON_LAND_NAME,
    RARE_LAND_NAME,
    EPIC_LAND_NAME,
    LEGENDARY_LAND_NAME,
    MYTHICAL_LAND_NAME
} from "../../../constants/land.constants";

import CommonLandVideo from "../../../resourses/lands/videos/Common__xvid.mp4";
import RareLandVideo from "../../../resourses/lands/videos/Rare__xvid.mp4";
import EpicLandVideo from "../../../resourses/lands/videos/Epic__xvid.mp4";
import LegendaryLandVideo from "../../../resourses/lands/videos/Legendary__xvid.mp4";
import MythicalLandVideo from "../../../resourses/lands/videos/Mithycal__xvid.mp4";

export default function Lands({ commonLandAmount, rareLandAmount, epicLandAmount, legendaryLandAmount, mythicalLandAmount }) {
    const lands = [
        { name: COMMON_LAND_NAME, video: CommonLandVideo, amount: commonLandAmount },
        { name: RARE_LAND_NAME, video: RareLandVideo, amount: rareLandAmount },
        { name: EPIC_LAND_NAME, video: EpicLandVideo, amount: epicLandAmount },
        { name: LEGENDARY_LAND_NAME, video: LegendaryLandVideo, amount: legendaryLandAmount },
        { name: MYTHICAL_LAND_NAME, video: MythicalLandVideo, amount: mythicalLandAmount },
    ];

    return (
        <div className={'lands-wrapper'}>
            <h3>Lands</h3>
            <div className={'lands'}>
                { lands.map(({ name, video, amount }) =>
                    <>
                        { !!amount &&
                            <div className={'land'} key={name}>
                                <div className={'print'}>
                                    <p>X{ amount }</p>
                                </div>
                                <video
                                    src={video}
                                    loop={true}
                                    autoPlay={true}
                                />
                                <p>{ name }</p>
                            </div>
                        }
                    </>
                ) }
            </div>
        </div>
    )
};
