import React from "react";

import './MarketFooter.scss';

import Authors from "../../Shared/Authors/Authors";
import Contacts from "../../Shared/Contacts/Contacts";

export default function MarketFooter() {
    return (
      <div className="market-footer">
          <Contacts />
          <Authors />
      </div>
    );
}
