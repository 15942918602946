import "./Vampires.scss";

import vampires from "../../../resourses/Flow_landing_assets/vampire_banner.png";
import blocto from "../../../resourses/Flow_landing_assets/blocto_icon.png";
import flow from "../../../resourses/Flow_landing_assets/flow_icon.png";
import Gesture from "../AnimationComponents/Gesture/Gesture";
import {LazyLoadImage} from "react-lazy-load-image-component";

function Vampires() {
    return (
        <div className={"vampires"} id={"vampires"}>
            <h2><span className={'red'}>EXCLUSIVE</span> BONUS: <span className={'red'}>VAMPIRE</span> CARDS</h2>
            <p>
                We've created a set of 50 unique vampire cards that will be exclusively offered within the Land Sale
                packs for the FLOW users only. Don't miss your chance to own them! The whole Vampire set will be hard
                limited to 50 000 cards.
            </p>
            <div className={"image-wrapper"}>
                <LazyLoadImage src={vampires} alt="" effect="blur" threshold={1000}/>
            </div>

            <h3>HOW TO BUY?</h3>
            <p className={"how-to-buy-text"}>
                The Dark Country lands NFTs will be available for purchase with Flow
                tokens via the Blocto wallet.
            </p>
            <p className={"how-to-buy-text"}>
                Miss something? Follow the link to Blocto to fill in the gap between you
                and your land!
            </p>

            <div className={"payment-icons"}>
                <a href={"https://blocto.portto.io/en/"} target={"_blank"} rel={'noreferrer'}>
                    <img src={blocto} alt=""/>
                </a>
                <a href={"https://www.onflow.org/"} target={"_blank"} rel={'noreferrer'}>
                    <img src={flow} alt=""/>
                </a>
            </div>

            <div className={"button"}>
                <Gesture>
                    <a
                        href={"https://blocto.portto.io/en/download"}
                        id={"blockto-button"}
                        target={'_blank'}
                        rel={'noreferrer'}
                    >
                        <span>GET THE BLOCTO WALLET</span>
                    </a>
                </Gesture>
            </div>
        </div>
    );
}

export default Vampires;
