import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import axios from "axios";

import './MyItems.scss';

import MarketFooter from "../../components/Market/MarketFooter/MarketFooter";
import Packs from "../../components/Landing/Packs/Packs";
import Cards from "../../components/MyItems/Cards/Cards";
import Lands from "../../components/MyItems/Lands/Lands";

import {
    COMMON_LAND_NAME,
    RARE_LAND_NAME,
    EPIC_LAND_NAME,
    LEGENDARY_LAND_NAME,
    MYTHICAL_LAND_NAME
} from "../../constants/land.constants";
import {
    MARKET_NFT_API,
    FLOW_UNPACKER_API
} from "../../constants/aws.constants";

import { readCollectionIds } from "../../flow";

export default function MyItems() {
    const [pack1Amount, setPack1Amount] = useState(0);
    const [pack2Amount, setPack2Amount] = useState(0);
    const [pack3Amount, setPack3Amount] = useState(0);
    const [bonusPackAmount, setBonusPackAmount] = useState(0);
    const [cards, setCards] = useState([]);
    const [commonLandAmount, setCommonLandAmount] = useState(0);
    const [rareLandAmount, setRareLandAmount] = useState(0);
    const [epicLandAmount, setEpicLandAmount] = useState(0);
    const [legendaryLandAmount, setLegendaryLandAmount] = useState(0);
    const [mythicalLandAmount, setMythicalLandAmount] = useState(0);

    const user = useSelector(({ auth }) => auth.auth);

    useEffect(() => {
        const getUserPacks = () => {
            readCollectionIds({ address: user.addr })
                .then(ids => fetchUserItems(ids))
                .catch((e) => console.log(e));
        };

        const fetchUserItems = (ids) => {
            axios.get(`${MARKET_NFT_API}/user-nfts?itemIds=${ids.join(',')}`)
                .then(({ data }) => groupPacks(data))
                .catch(error => console.log(error));

            axios.get(`${FLOW_UNPACKER_API}/user-lands-and-cards?itemIds=${ids.join(',')}`)
                .then(({ data }) => groupLandsAndCards(data))
                .catch(error => console.log(error));
        };

        const groupPacks = async (packs) => {
            setPack1Amount(packs.filter(({ data: { name } }) => name.includes('Rancho')).length);
            setPack2Amount(packs.filter(({ data: { name } }) => name.includes('Mayor')).length);
            setPack3Amount(packs.filter(({ data: { name } }) => name.includes('Governor')).length);
            setBonusPackAmount(packs.filter(({ data: { name } }) => name.includes('Bonus')).length);
        };

        const groupLandsAndCards = async (data) => {
            setCards(data.filter(({ type }) => type === 'card'));

            setCommonLandAmount(data.filter(({ data: { name } }) => name === COMMON_LAND_NAME).length);
            setRareLandAmount(data.filter(({ data: { name } }) => name === RARE_LAND_NAME).length);
            setEpicLandAmount(data.filter(({ data: { name } }) => name === EPIC_LAND_NAME).length);
            setLegendaryLandAmount(data.filter(({ data: { name } }) => name === LEGENDARY_LAND_NAME).length);
            setMythicalLandAmount(data.filter(({ data: { name } }) => name === MYTHICAL_LAND_NAME).length);
        };

        if (user.loggedIn)
            getUserPacks();
    }, [user]);

    return (
        <div className="my-items-wrapper">
            <div className="my-items-content">
                <p className="title">My Items</p>

                <p className="title">Packs</p>
                <Packs
                    showTitle={false}
                    pack1Amount={pack1Amount}
                    pack2Amount={pack2Amount}
                    pack3Amount={pack3Amount}
                    showBonusPack={true}
                    pack4Amount={bonusPackAmount}
                />

                { !!commonLandAmount || !!rareLandAmount || !!epicLandAmount || !!legendaryLandAmount || !!mythicalLandAmount
                    ? <Lands
                        commonLandAmount={commonLandAmount}
                        rareLandAmount={rareLandAmount}
                        epicLandAmount={epicLandAmount}
                        legendaryLandAmount={legendaryLandAmount}
                        mythicalLandAmount={mythicalLandAmount}
                    />
                    : <></>
                }

                { cards.length
                    ? <Cards cards={cards} />
                    : <></>
                }

            </div>
            <MarketFooter />
        </div>
    );
}
