//import Particles from "react-particles-js";
import { particlesConfig } from "./ParticlesConfig";

const ParticleBackground = () => {
  return (
      <div />
      //<Particles params={particlesConfig} />
  );
};

export default ParticleBackground;
