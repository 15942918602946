import * as fcl from "@blocto/fcl";


import { tx } from "../util/tx";

import {
    DARKCOUNTRY_ADDRESS,
    DARKCOUNTRY_MARKET_ADDRESS,
    FUNGIBLE_TOKEN_ADDRESS,
    FLOW_TOKEN_ADDRESS,
    NON_FUNGIBLE_TOKEN_ADDRESS
} from "../../constants/contract.constants";

const CODE = `
    import NonFungibleToken from ${NON_FUNGIBLE_TOKEN_ADDRESS} 
    import DarkCountry from ${DARKCOUNTRY_ADDRESS} 
    import DarkCountryMarket from ${DARKCOUNTRY_MARKET_ADDRESS} 
    import FungibleToken from ${FUNGIBLE_TOKEN_ADDRESS} 
    import FlowToken from ${FLOW_TOKEN_ADDRESS} 
    
    transaction(saleItemIDs: [UInt64], marketCollectionAddress: Address) {
        let darkCountryCollection: &DarkCountry.Collection{NonFungibleToken.Receiver}
        let marketCollection: &DarkCountryMarket.Collection{DarkCountryMarket.CollectionPublic}
    
        prepare(signer: AuthAccount) {
        
            // if the account doesn't already have a collection
            if signer.borrow<&DarkCountry.Collection>(from: DarkCountry.CollectionStoragePath) == nil {
    
                // create a new empty collection
                let collection <- DarkCountry.createEmptyCollection()
    
                // save it to the account
                signer.save(<-collection, to: DarkCountry.CollectionStoragePath)
    
                // create a public capability for the collection
                signer.link<&DarkCountry.Collection{NonFungibleToken.CollectionPublic, DarkCountry.DarkCountryCollectionPublic}>(DarkCountry.CollectionPublicPath, target: DarkCountry.CollectionStoragePath)
            }
            
            // if the account doesn't already have a collection
            if signer.borrow<&DarkCountryMarket.Collection>(from: DarkCountryMarket.CollectionStoragePath) == nil {
    
                // create a new empty collection
                let collection <- DarkCountryMarket.createEmptyCollection() as! @DarkCountryMarket.Collection
    
                // save it to the account
                signer.save(<-collection, to: DarkCountryMarket.CollectionStoragePath)
    
                // create a public capability for the collection
                signer.link<&DarkCountryMarket.Collection{DarkCountryMarket.CollectionPublic}>(DarkCountryMarket.CollectionPublicPath, target: DarkCountryMarket.CollectionStoragePath)
            }
            
            self.marketCollection = getAccount(marketCollectionAddress)
                .getCapability<&DarkCountryMarket.Collection{DarkCountryMarket.CollectionPublic}>(
                    DarkCountryMarket.CollectionPublicPath
                )!
                .borrow()
                ?? panic("Could not borrow market collection from market address")
            
            self.darkCountryCollection = signer.borrow<&DarkCountry.Collection{NonFungibleToken.Receiver}>(
                    from: DarkCountry.CollectionStoragePath
                ) ?? panic("Cannot borrow DarkCountry collection receiver from acct")
                
            let mainFlowTokenVault = signer.borrow<&FlowToken.Vault>(from: /storage/flowTokenVault)
                ?? panic("Cannot borrow FlowToken vault from acct storage")
            
            // iterate through the sale items
            var i = 0
            while i < saleItemIDs.length {
                let saleItem = self.marketCollection.borrowSaleItem(itemID: saleItemIDs[i])
                        ?? panic("No item with that ID")
                let price = saleItem.price
            
                var paymentVault <- mainFlowTokenVault.withdraw(amount: price)
                self.marketCollection.purchase(
                    itemID: saleItemIDs[i],
                    buyerCollection: self.darkCountryCollection,
                    buyerPayment: <- paymentVault
                )
                
                // move to the next item
                i = i + 1
            }
        }
    }
`;

export function buyMarketItem({ saleItemIDs, marketCollectionAddress }) {
    console.log({ saleItemIDs });

    return tx(
        {
            cadence: CODE,
            args: (arg, t) => [
                arg(saleItemIDs,  t.Array(t.UInt64)),
                arg(marketCollectionAddress, t.Address),
              ],
              proposer: fcl.currentUser,
              payer: fcl.currentUser,
              authorizations: [fcl.currentUser],
              limit: 1000,
        }
    )
}
