import React from "react";
import { Link } from "react-router-dom";

import flowLogo from "../../../resourses/Flow_landing_assets/flowLOGO2.png";
import dcLogo from "../../../resourses/Flow_landing_assets/dcLOGO.png";
import vampires from "../../../resourses/Flow_landing_assets/vampire_cards.png";
import gold from "../../../resourses/Flow_landing_assets/Gold_Book_C3 (1).png";
import silver from "../../../resourses/Flow_landing_assets/Silver_Book_C3_1 (1).png";
import bronze from "../../../resourses/Flow_landing_assets/Bronze_Book_C3 (1).png";

import "./StartBlock.scss";

import FadeOut from "../AnimationComponents/FadeOut/FadeOut";
import ParticleBackground from "../Particles/Particles";
import Header from "../Header/Header";
import Countdown from "../../Shared/Countdown/Countdown";

function StartBlock({visibility}) {
    return (
        <div className={'main-part'}>
            <Header />
            <div className={`start-block`}>

                <ParticleBackground/>

                {/*<div className={"upper-block"}>*/}
                {/*  <p>BECOME A LANDLORD -</p>*/}
                {/*  <p>OWN, FARM, TRADE, CREATE!</p>*/}
                {/*</div>*/}

                <div className="middle-block vertical-center">
                    <div className={"logos-wrapper"}>
                        <a
                            href={"https://www.onflow.org/post/partner-spotlight-dark-country"}
                            target={"_blank"}
                            rel={'noreferrer'}
                        >
                            <img src={flowLogo} alt=""/>
                        </a>
                        <a href="https://darkcountry.io/" target={"_blank"} rel={'noreferrer'}>
                            <img src={dcLogo} alt=""/>
                        </a>
                    </div>
                    <p>
                        <h1 className={"text-center"}>
                            JOIN the <span className={"white-color"}>GENESIS</span>
                        </h1>
                        <h1>
                            <span className={"white-color"}>Dark Country</span> Land{" "}
                            <span className={"white-color"}>Sale*</span>
                        </h1>
                    </p>
                </div>

                <div className="bottom-block vertical-center">
                    <div className={"packs-header"}>
                        <FadeOut>
                            <img src={bronze} alt=""/>
                            <span>Rancho pack</span>
                        </FadeOut>
                        <FadeOut>
                            <img src={silver} alt=""/>
                            <span>Mayor's pack</span>
                        </FadeOut>
                        <FadeOut>
                            <img src={gold} alt=""/>
                            <span>Governor's pack</span>
                        </FadeOut>
                    </div>
                    <div className={"button-container"}>
                        {/*<div className={'timer'}>*/}
                        {/*    <Countdown*/}
                        {/*        date={new Date("05/18/2021 02:0:0 PM UTC")}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        <div className={'join-button-wrapper'}>
                            <Link to="/unpacker/land">
                                <div className={"join-button"}>
                                    <span className={'white-color'}>LAND UNPACKER</span>
                                </div>
                            </Link>

                        </div>
                    </div>
                    <div className={"vampire-cards"}>
                        <p>
                            * Includes a set of 50 unique Vampire Cards exclusively available to FLOW users only
                        </p>
                        <FadeOut>
                            <img src={vampires} alt=""/>
                        </FadeOut>
                    </div>
                </div>
                {/*<div className={"timer"}>*/}
                {/*  <div className={"intro"}>*/}
                {/*    <div>*/}
                {/*      Intro Sale: <span className={"red"}>3D 10H 2S</span>*/}
                {/*    </div>*/}
                {/*    <div className={"print"}>*/}
                {/*      <p>Pre-registered</p>*/}
                {/*      <p>whitelisters ONLY!</p>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*  <div className={"main"}>*/}
                {/*    <Countdown*/}
                {/*      date={new Date("2021-05-06T00:00:00")}*/}
                {/*      renderer={renderer}*/}
                {/*    />*/}
                {/*    /!*Main Sale: <span className={"red"}>3D 10H 2S</span>*!/*/}
                {/*  </div>*/}
                {/*</div>*/}
            </div>
        </div>
    );
}

export default StartBlock;
