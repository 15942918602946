import React from "react";

import './MarketPack.scss';

import CountButtons from "../../../Shared/CountButtons/CountButtons.js";

export default function MarketPack(props) {
    const {
        amount,
        left,
        video,
        isMobile,
        mouseOver,
        mouseOut,
        name,
        landPlots,
        cards,
        price,
        showCountButtons,
        reserve,
        counter,
        allowIncrement,
        onChangeCount
    } = props;

    return (
        <div className={"single-pack market-pack"}>
            <div>
                <div className={"print"}>
                    <p>X{ amount }</p>
                </div>
                <video loop={true} muted={true} src={video}
                       autoPlay={isMobile}
                       onMouseOver={isMobile ? null : mouseOver}
                       onMouseOut={isMobile ? null : mouseOut}
                >
                </video>
            </div>
            <div className={"pack-info"}>
                <p>{ name}</p>
                <p>{ landPlots } land plots + { cards } cards</p>
            </div>
            {showCountButtons &&
                <>
                    <p className="packs-left"><span> { left } </span> / { amount } Left</p>
                    <p className="reserve">Your reserve: <span> { reserve } </span> Packs</p>
                    <div className="price">
                        <p>{ price } FLOW</p>
                        <CountButtons
                            onChangeCount={onChangeCount}
                            countInCart={counter}
                            allowIncrement={allowIncrement}
                            left={left}
                        />
                    </div>
                </>
            }
        </div>
    );
}
