import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as fcl from "@blocto/fcl";

import { Button } from "@material-ui/core";

import './Auth.scss';

import { AUTH_LOGIN_SUCCESS, AUTH_LOGOUT_SUCCESS } from "../../constants/auth.constants";

export default function Auth() {
    const dispatch = useDispatch();

    const user = useSelector(({ auth }) => auth.auth);


    useEffect(() => {
        fcl.currentUser().subscribe(user => {
            if (user.loggedIn) {

                dispatch({
                    type: AUTH_LOGIN_SUCCESS,
                    payload: user
                });
            }
        });
    }, [ ]);

    const signIn = () => {
        fcl.authenticate().then(user => {
            dispatch({
                type: AUTH_LOGIN_SUCCESS,
                payload: user
            });
        })
        
    }

    const sighOut = () => {
        fcl.unauthenticate();


        dispatch({
            type: AUTH_LOGOUT_SUCCESS
        });
    }

    if (user.addr) {
        return (
            <div className="user-info">
                <p>{ user?.addr ?? "No Address" }</p>
                <Button
                    type="submit"
                    variant="outlined"
                    color="secondary"
                    onClick={ sighOut }
                >
                    { 'Sign Out' }
                </Button>
            </div>
        );
    }

    return (
        <div className="sign-in">
            <Button
                type="submit"
                variant="outlined"
                color="secondary"
                onClick={ signIn }
            >
                { 'Sign In' }
            </Button>
        </div>
    );
}
