import React from "react";

import { Button } from "@material-ui/core";

import './CustomButton.scss';

export default function CustomButton({ text, onClick, disabled = false }) {
    return (
        <Button
            type="submit"
            color="secondary"
            className="custom-button"
            onClick={onClick}
            disabled={disabled}
        >
            {text}
        </Button>
    );
}
