import React, { useEffect, useState } from 'react'
import axios from 'axios';

import bronzeVideo from '../../../resourses/3d/bronze_60F_x264.mp4'
import silverVideo from '../../../resourses/3d/Silver_60F_x264.mp4'
import goldVideo from '../../../resourses/3d/Gold_60F_x264.mp4'

import './MarketPacks.scss';
import '../../Landing/Packs/Packs.scss';

import MarketPack from "./MarketPack/MarketPack";

import {
    PACK_1_ID,
    PACK_1_NAME,
    PACK_1_AMOUNT,
    PACK_1_PRICE,
    PACK_1_LAND_PLOTS,
    PACK_1_CARDS,
    PACK_2_ID,
    PACK_2_NAME,
    PACK_2_AMOUNT,
    PACK_2_CARDS,
    PACK_2_LAND_PLOTS,
    PACK_2_PRICE,
    PACK_3_ID,
    PACK_3_NAME,
    PACK_3_AMOUNT,
    PACK_3_CARDS,
    PACK_3_LAND_PLOTS,
    PACK_3_PRICE
} from "../../../constants/pack.constants";
import {
    MINTED_NFT_ENDPOINT
} from "../../../constants/aws.constants";

export default function MarketPacks(props) {
    const {
        showCountButtons = false,
        userPack1Reserve,
        userPack2Reserve,
        userPack3Reserve,
        setTotalOrder,
        resetCounters,
        setUserReserve,
        handleSoldOut,
        soldOut,
        setPacksToBuy
    } = props;

    const [width, setWidth] = useState(window.innerWidth);

    const [packsLoading, setPacksLoading] = useState(true);

    const [pack1Counter, changePack1Counter] = useState(0);
    const [pack2Counter, changePack2Counter] = useState(0);
    const [pack3Counter, changePack3Counter] = useState(0);

    const [pack1Left, setPack1Left] = useState(0);
    const [pack2Left, setPack2Left] = useState(0);
    const [pack3Left, setPack3Left] = useState(0);

    const [allowPack1Increment, setAllowPack1Increment] = useState(userPack1Reserve > 0);
    const [allowPack2Increment, setAllowPack2Increment] = useState(userPack2Reserve > 0);
    const [allowPack3Increment, setAllowPack3Increment] = useState(userPack3Reserve > 0);

    useEffect(() => getPacks(), []);

    useEffect(() => {
        setAllowPack1Increment(pack1Counter < userPack1Reserve);
        handleTotalOrderCount();
    },[pack1Counter, userPack1Reserve]);

    useEffect(() => {
        setAllowPack2Increment(pack2Counter < userPack2Reserve);
        handleTotalOrderCount();
    },[pack2Counter, userPack2Reserve]);

    useEffect(() => {
        setAllowPack3Increment(pack3Counter < userPack3Reserve);
        handleTotalOrderCount();
    },[pack3Counter, userPack3Reserve]);

    useEffect(() => {
        setUserReserve(pack1Counter, PACK_1_ID);
        setUserReserve(pack2Counter, PACK_2_ID);
        setUserReserve(pack3Counter, PACK_3_ID);

        getPacks();

        changePack1Counter(0);
        changePack2Counter(0);
        changePack3Counter(0);
        setTotalOrder(0);

        setPacksToBuy({
            pack1ToBuy: 0,
            pack2ToBuy: 0,
            pack3ToBuy: 0,
        });
    }, [resetCounters]);

    useEffect(() => {
        if ((pack1Left + pack2Left + pack3Left) === 0 && !packsLoading)
            handleSoldOut();

    }, [pack1Left, pack2Left, pack3Left, packsLoading]);

    useEffect(() => {
        setPacksToBuy({
            pack1ToBuy: pack1Counter,
            pack2ToBuy: pack2Counter,
            pack3ToBuy: pack3Counter
        });
    }, [pack1Counter, pack2Counter, pack3Counter]);

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => window.removeEventListener('resize', handleWindowSizeChange);
    }, []);

    const getPacks = () => {
        axios.get(`${MINTED_NFT_ENDPOINT}/packs-left`)
            .then(({ data: { pack1count, pack2count, pack3count } }) => {
                setPack1Left(parseInt(pack1count));
                setPack2Left(parseInt(pack2count));
                setPack3Left(parseInt(pack3count));
                setPacksLoading(false);
            });
    }

    const handleTotalOrderCount = () => {
        const pack1TotalPrice = pack1Counter * PACK_1_PRICE;
        const pack2TotalPrice = pack2Counter * PACK_2_PRICE;
        const pack3TotalPrice = pack3Counter * PACK_3_PRICE;

        setTotalOrder(pack1TotalPrice + pack2TotalPrice + pack3TotalPrice);
    }

    const handleWindowSizeChange = () => setWidth(window.innerWidth);

    const isMobile = width <= 1024

    const mouseOver = (e) => e.target.play();
    const mouseOut = (e) => e.target.pause();

    return (
        <div className={soldOut ? "packs blur" : "packs"}>
            <p className={"title"}>
                <p>Choose your</p>
                <p>
                    <span className={"red"}>Land Pack</span> and get Unique Vampire Cards!
                </p>
            </p>
            <p className={"details"}>
                Each pack contains land plot(s), so once unpacked you will be able to claim a land.
            </p>

            <div className={"packs-wrapper"}>
                <MarketPack
                    amount={PACK_1_AMOUNT}
                    left={pack1Left}
                    video={bronzeVideo}
                    isMobile={isMobile}
                    mouseOver={mouseOver}
                    mouseOut={mouseOut}
                    name={PACK_1_NAME}
                    landPlots={PACK_1_LAND_PLOTS}
                    cards={PACK_1_CARDS}
                    price={PACK_1_PRICE}
                    reserve={userPack1Reserve}
                    showCountButtons={showCountButtons}
                    counter={pack1Counter}
                    allowIncrement={allowPack1Increment}
                    onChangeCount={changePack1Counter}
                />
                <MarketPack
                    amount={PACK_2_AMOUNT}
                    left={pack2Left}
                    video={silverVideo}
                    isMobile={isMobile}
                    mouseOver={mouseOver}
                    mouseOut={mouseOut}
                    name={PACK_2_NAME}
                    landPlots={PACK_2_LAND_PLOTS}
                    cards={PACK_2_CARDS}
                    price={PACK_2_PRICE}
                    reserve={userPack2Reserve}
                    showCountButtons={showCountButtons}
                    counter={pack2Counter}
                    allowIncrement={allowPack2Increment}
                    onChangeCount={changePack2Counter}
                />
                <MarketPack
                    amount={PACK_3_AMOUNT}
                    left={pack3Left}
                    video={goldVideo}
                    isMobile={isMobile}
                    mouseOver={mouseOver}
                    mouseOut={mouseOut}
                    name={PACK_3_NAME}
                    landPlots={PACK_3_LAND_PLOTS}
                    cards={PACK_3_CARDS}
                    price={PACK_3_PRICE}
                    reserve={userPack3Reserve}
                    showCountButtons={showCountButtons}
                    counter={pack3Counter}
                    allowIncrement={allowPack3Increment}
                    onChangeCount={changePack3Counter}
                />
            </div>
        </div>
    );
}
