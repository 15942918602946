import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { reducers } from './reducers';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { logger } from 'redux-logger';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';

import * as fcl from "@blocto/fcl";

import './index.scss';

import App from './App';

//TESTNET
// fcl
//   .config({
//     "accessNode.api": "https://access-testnet.onflow.org",
//     "discovery.wallet": "https://wallet-v2-dev.blocto.app/api/flow/authn",
//     "flow.network":  'testnet',
//     "discovery.wallet.method": "HTTP/POST",
//     "app.detail.id": "3d5acaff-58c6-4991-a2e1-0e61b9a4da0d"
//   })

//MAINNET
fcl
  .config({
    "accessNode.api": "https://rest-mainnet.onflow.org",
    "discovery.wallet": "https://wallet-v2.blocto.app/api/flow/authn",
    "flow.network": "mainnet",
    "discovery.wallet.method": "HTTP/POST",
    "app.detail.id": "3d5acaff-58c6-4991-a2e1-0e61b9a4da0d"
  })

let middleware = [];

if (process.env.NODE_ENV === "development") {
    middleware = [...middleware, logger];
} else {
    middleware = [...middleware];
}

export const store = createStore(
    reducers,
    composeWithDevTools(applyMiddleware(...middleware))
);

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Router>
                <App />
            </Router>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
