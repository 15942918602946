import React from "react";

import './Cards.scss';

export default function Cards({ cards }) {
    return (
        <div className={'cards-wrapper'}>
            <h3>Cards</h3>
            <div className={'cards'}>
                { cards.map(({ data: { name, mediaUrl } }, index) =>
                    <div className={'card'} key={`${name}${index}`}>
                        <img src={mediaUrl} alt="" />
                        <p>{ name }</p>
                    </div>
                ) }
            </div>
        </div>
    )
};
