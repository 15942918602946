import {useEffect, useState} from 'react'

import bronzeVideo from '../../../resourses/3d/bronze_60F_x264.mp4'
import silverVideo from '../../../resourses/3d/Silver_60F_x264.mp4'
import goldVideo from '../../../resourses/3d/Gold_60F_x264.mp4'
import bonusVideo from '../../../resourses/3d/Bonus_60F.webm';

import "./Packs.scss";

import Pack from '../Pack/Pack';


function Packs({ showTitle = true, pack1Amount = 2100, pack2Amount = 300, pack3Amount = 100, showBonusPack = false, pack4Amount }) {
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    let isMobile = width <= 1024

    const mouseOver = (e) => e.target.play();
    const mouseOut = (e) => e.target.pause();

    return (
        <div className={"packs"} id={"packs"}>
            { showTitle &&
                <>
                    <p className={"title"}>
                        <p>Choose your</p>
                        <p>
                            <span className={"red"}>Land Pack</span> and get Unique Vampire Cards!
                        </p>
                    </p>
                    <p className={"details"}>
                        Each pack contains land plot(s), so once unpacked you will be able to
                        claim a land.
                    </p>
                </>
            }

            <div className={"packs-wrapper"}>
                <Pack
                    amount={pack1Amount}
                    video={bronzeVideo}
                    isMobile={isMobile}
                    mouseOver={mouseOver}
                    mouseOut={mouseOut}
                    name={'Rancho pack'}
                    landPlots={1}
                    cards={5}
                    price={7}
                />
                <Pack
                    amount={pack2Amount}
                    video={silverVideo}
                    isMobile={isMobile}
                    mouseOver={mouseOver}
                    mouseOut={mouseOut}
                    name={'Mayor\'s pack'}
                    landPlots={3}
                    cards={15}
                    price={20}
                />
                <Pack
                    amount={pack3Amount}
                    video={goldVideo}
                    isMobile={isMobile}
                    mouseOver={mouseOver}
                    mouseOut={mouseOut}
                    name={'Governor\'s Pack'}
                    landPlots={10}
                    cards={50}
                    price={60}
                />

                { showBonusPack &&
                    <Pack
                        amount={pack4Amount}
                        video={bonusVideo}
                        isMobile={isMobile}
                        mouseOver={mouseOver}
                        mouseOut={mouseOut}
                        name={'Bonus Pack'}
                        vampireCards={5}
                    />
                }
            </div>
        </div>
    );
}

export default Packs;
