import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import React, { useEffect } from "react";

export const SlideInLeft = ({ children }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            controls.start("visible");
        }

        return () => controls.start("hidden");
    }, [inView, controls]);

  const variants = {
      hidden: { x: '-300px'},
      visible: {
          x: '0px'
      }
  }
  return (
    <motion.div
      ref={ref}
      animate={controls}
      variants={variants}
      transition={{ duration: 1.5, type: 'easeOut'}}
      initial={'hidden'}
    >
      {children}
    </motion.div>
  );
};

export const SlideInRight = ({ children }) => {
    const controls = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            controls.start("visible");
        }
        return () => controls.start("hidden");
    }, [controls, inView]);

    const variants = {
        hidden: {scale: 1, x: '300px'},
        visible: {
            scale: 1,
            x: '0px'
        }
    }
    return (
        <motion.div
            ref={ref}
            animate={controls}
            variants={variants}
            transition={{ duration: 1.5, type: 'easeOut' }}
            initial={'hidden'}
        >
            {children}
        </motion.div>
    );
};
