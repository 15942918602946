import React from 'react';
import ReactCountdown, { zeroPad } from 'react-countdown-now';

import './Countdown.scss';

const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed)
        return <p className="sale-is-live">SOLD OUT</p>

    return <div className="sale-timer">
        <p>The main sale will start in:</p>
        <div className="countdown-container">
            { days > 1 &&
                <>
                    <div className="countdown-days">{zeroPad(days, 1)}</div>
                    <div className="countdown-days-text">{'days'}</div>
                </>
            }
            <div className="countdown-hours">{zeroPad(hours, 2)}</div>
            <div className="countdown-dots">:</div>
            <div className="countdown-minutes">{zeroPad(minutes, 2)}</div>
            <div className="countdown-dots">:</div>
            <div className="countdown-seconds">{zeroPad(seconds, 2)}</div>
        </div>
    </div>;
}

export default function Countdown({ date, handleSaleStart }) {
    return <ReactCountdown date={date} renderer={renderer} onComplete={handleSaleStart} />
}
