import { Link } from "react-scroll";

import "./Footer.scss";

import dcLogo from "../../../resourses/Flow_landing_assets/dcLOGO.png";
import itch from "../../../resourses/Flow_landing_assets/inchLOGO.png";

import Contacts from "../../Shared/Contacts/Contacts";
import Authors from "../../Shared/Authors/Authors";

function FreeVirgin() {
  return (
    <div className={"footer"}>
      <h2>JOIN THE GAME</h2>
      <p>Love the Trading Card Games like we do? Try the game right away!</p>

      <div className={"logos-wrapper"}>
        <div>
          <a target={"_blank"} href={"https://darkcountry.io/"} rel={'noreferrer'}>
            <img src={dcLogo} alt="" />
            <p>Visit out website</p>
          </a>
        </div>
        <div>
          <a
            href={"https://darkcountry.itch.io/dark-country"}
            target={"_blank"}
            rel={'noreferrer'}
          >
            <img src={itch} alt="" />
            <p>Download and play</p>
          </a>
        </div>
      </div>

      <Contacts />

      <div className={"footer-nav"}>
        <span>
          <Link to="packs" smooth={true} href={""} duration={5000}>
            Packs
          </Link>
        </span>
        <span>
          <Link to="whitelist" smooth={true} href={""} duration={4000}>
            Whitelist
          </Link>
        </span>
        <span>
          <Link to="about" smooth={true} href={""} duration={3000}>
            About DC
          </Link>
        </span>
        <span>
          <Link to="lands" smooth={true} href={""} duration={3000}>
            Lands
          </Link>
        </span>
        <span>
          <Link to="vampires" smooth={true} href={""} duration={3000}>
            Vampires
          </Link>
        </span>
        <span>
          <Link to="unpacking" smooth={true} href={""} duration={3000}>
            Unpacking
          </Link>
        </span>
        <span>
          <Link to="roadmap" smooth={true} href={""} duration={3000}>
            Roadmap
          </Link>
        </span>
        <span>
          <Link to="promo" smooth={true} href={""} duration={2000}>
            Promo
          </Link>
        </span>
        <span>
          <Link to="contact" smooth={true} href={""} duration={2000}>
            Contact
          </Link>
        </span>
      </div>

      <Authors />
    </div>
  );
}

export default FreeVirgin;
