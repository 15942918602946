import React from "react";

import './Contacts.scss';

import telegram from "../../../resourses/Flow_landing_assets/telegramLOGO.png";
import discord from "../../../resourses/Flow_landing_assets/discordLOGO.png";
import medium from "../../../resourses/Flow_landing_assets/mediumLOGO.png";
import twitter from "../../../resourses/Flow_landing_assets/twitterLOGO.png";

export default function Contacts() {
    return (
        <div className={"contacts"} id={"contact"}>
            <h3>Contact us</h3>
            <p>
                If you have any questions about Land assets, please contact us in
                following ways
            </p>

            <div className={"contact-icons"}>
                <div>
                    <a href={"https://t.me/dark_country"} target={"_blank"} rel={'noreferrer'}>
                        <img src={telegram} alt="" />
                        <span>@DARK_COUNTRY</span>
                    </a>
                </div>
                <div>
                    <a href="https://discord.gg/8R4SaZY" target={"_blank"} rel={'noreferrer'}>
                        <img src={discord} alt="" />
                        <p>@DARK_COUNTRY</p>
                    </a>
                </div>
                <div>
                    <a
                        href="https://darkcountrygame.medium.com/"
                        target={"_blank"}
                        rel={'noreferrer'}
                    >
                        <img src={medium} alt="" />
                        <p>Dark Country</p>
                    </a>
                </div>
                <div>
                    <a href={"https://twitter.com/DarkCountryGame"}>
                        <img src={twitter} alt="" />
                        <p>@DarkCountryGame</p>
                    </a>
                </div>
            </div>
        </div>
    );
}
