import {motion} from "framer-motion";

const Gesture = ({children}) => (
    <motion.div
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9, x: "-5px", y: "5px" }}
    >
        {children}
    </motion.div>
);

export default Gesture