import React from "react";

import './Pack.scss';

export default function Pack(props) {
    const {
        amount,
        video,
        isMobile,
        mouseOver,
        mouseOut,
        name,
        landPlots,
        cards,
        price,
        vampireCards
    } = props;

    return (
        <div className={"single-pack"}>
            <div>
                <div className={"print"}>
                    <p>X{ amount }</p>
                </div>
                <video loop={true} muted={true} src={video}
                       autoPlay={isMobile}
                       onMouseOver={isMobile ? null : mouseOver}
                       onMouseOut={isMobile ? null : mouseOut}
                />
            </div>
            <div className={"pack-info"}>
                <p>{ name}</p>
                <p>CONTAINS:</p>

                { vampireCards &&
                    <p>{ vampireCards } Vampire cards</p>
                }

                { !vampireCards &&
                    <>
                        <p>{ landPlots } land plots</p>
                        <p>+ { cards } cards</p>
                        <p>Price : { price } FLOW</p>
                    </>
                }
            </div>
        </div>
    );
}
