import "./Partners.scss";

import FadeInWhenVisible from "../AnimationComponents/FadeInWhenVisible/FadeInWhenVisible";

import flowLogo from "../../../resourses/Flow_landing_assets/flowLOGO2.png";
import dappradar from "../../../resourses/Flow_landing_assets/dappradarLOGO.png";
import dapper from "../../../resourses/Flow_landing_assets/dapperLOGO.png";
import dapp from "../../../resourses/Flow_landing_assets/dappLOGO.png";
import blockto from "../../../resourses/Flow_landing_assets/bloctologo.png";
import dappR from "../../../resourses/Flow_landing_assets/dapp.png";

function Partners() {
  return (
    <FadeInWhenVisible>
      <div className={"partners"}>
        <h2>OUR PARTNERS</h2>
        <p>
          Dark Country work together with the best companies and media to ensure
          we bring the product of the highest quality to meet all the
          expectations of our community.
        </p>
          <div>
            <div>
              <div className={"dappradar-logo"}>
                <a href="https://dappradar.com/" target={"_blank"} rel={'noreferrer'}>
                  <img src={dappradar} alt="" />
                </a>
              </div>
              <div>
                <a href="https://www.onflow.org/" target={"_blank"} rel={'noreferrer'}>
                  <img src={flowLogo} alt="" />
                </a>
              </div>
              <div>
                <a href="https://dapp.review/" target={"_blank"} rel={'noreferrer'}>
                  <img src={dappR} alt="" />
                </a>
              </div>
            </div>
            <div>
              <div>
                <a href="">
                  <img src={dapper} alt="" />
                </a>
              </div>
              <div>
                <a href="https://blocto.portto.io/" target={"_blank"} rel={'noreferrer'}>
                  <img src={blockto} alt="" />
                </a>
              </div>
              <div>
                <a href="https://www.dapp.com/" target={"_blank"} rel={'noreferrer'}>
                  <img src={dapp} alt="" />
                </a>
              </div>
            </div>
          </div>
      </div>
    </FadeInWhenVisible>
  );
}

export default Partners;
