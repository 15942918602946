import React from "react";

import { Button } from "@material-ui/core";

import './CountButtons.scss';

export default function CountButtons({ onChangeCount, countInCart, allowIncrement, left }) {
    const disabled = left === 0 || countInCart > left - 1;

    return (
        <div className="count-buttons-wrapper">
            <Button
                onClick={() => onChangeCount(countInCart -= 1)}
                id="shop-pack-from-cart"
                disabled={!(countInCart > 0)}
                className="button-count"
            >
                <p className="button-text">-</p>
            </Button>

            <p className="count-text">{countInCart}</p>

            <Button
                onClick={() => {
                    if (allowIncrement)
                        onChangeCount(countInCart += 1)
                }}
                id="shop-pack-add-to-cart"
                className="button-count"
                disabled={disabled}
            >
                <p className="button-text">+</p>
            </Button>
        </div>
    );
}
