import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";

import './Purchase.scss';

import CustomButton from "../../Shared/CustomButton/CustomButton";
import Countdown from "../../Shared/Countdown/Countdown";
import QueueDataModal from "../../../modals/QueueDataModal/QueueDataModal"
import PurchaseCongratulationModal from "../../../modals/PurchaseCongratulationModal/PurchaseCongratulationModal"

import { buyMarketItem } from "../../../flow";

import { DARKCOUNTRY_MARKET_ADDRESS } from "../../../constants/contract.constants";
import { MINTED_NFT_ENDPOINT, QUEUE_ENDPOINT } from "../../../constants/aws.constants";
import { SUCCESS_PURCHASE_STATUS, TIMEOUT_PURCHASE_STATUS } from "../../../constants/status.constants";
import {
    PACK_1_ITEM_TEMPLATE_ID, PACK_2_ITEM_TEMPLATE_ID, PACK_3_ITEM_TEMPLATE_ID
} from "../../../constants/pack.constants";

const SALE_START_TIME = '05/18/2021 02:0:0 PM UTC';

export default function Purchase({ total, successPurchase, packsToBuy }) {
    const [purchase, setPurchase] = useState(false);
    const [purchasing, setPurchasing] = useState(false);
    const [enrolling, setEnrolling] = useState(false);
    const [inQueue, setInQueue] = useState(false);
    const [queueNumber, setQueueNumber] = useState(0);
    const [finishedUsersCounter, setFinishedUsersCounter] = useState(0);
    const [queueData, showQueueData] = useState(false);
    const [queueDataShowed, setQueueDataShowed] = useState(false);
    const [congratulationModal, showCongratulationModal] = useState(false);
    const [saleStarted, setSaleStarted] = useState(false);
    const [reserved, setReserved] = useState([]);
    const [hideEnroll, setHideEnroll] = useState(false);
    const [userQueueTimestamp, setUserQueueTimestamp] = useState(0);
    const [userAddress, setUserAddress] = useState(null);

    const user = useSelector(({ auth }) => auth.auth);

    useEffect(() => {
        if (user && user.addr) {
            setUserAddress(user.addr);
        }
    }, [user]);

    useEffect(() => {
        const now = new Date().getTime();
        const saleTime = new Date(Date.parse(SALE_START_TIME)).getTime();

        if (now > saleTime)
            handleSaleStart();
    }, [])

    useEffect(() => getUserFromQueue(), [userAddress]);

    useEffect(() => {
        const interval = setInterval(() => getUserFromQueue(userAddress), 10 * 1000);
        return () => clearInterval(interval);
    }, [userAddress]);

    useEffect(() => {
        const checkUserQueueTimestamp = () => {
            const now = new Date().getTime();

            if ((now - 5 * 60 * 1000) > userQueueTimestamp)
                finishPurchasing();
        };

        const queueTimestampInterval = setInterval(() => {
            if (userQueueTimestamp)
                checkUserQueueTimestamp()
        }, 10 * 1000);

        return () => clearInterval(queueTimestampInterval);
    }, []);

    const finishPurchasing = () => {
        setPurchase(false);
        setHideEnroll(true);
        setUserQueueTimestamp(0);
    }

    const getUserFromQueue = (showPopup = true) => {
        if (!userAddress)
            return;

        axios.get(`${QUEUE_ENDPOINT}?address=${userAddress}`)
            .then(({ data: { queueNumber, statusMsg, inQueue, finishedUsersCounter, queueTimestamp } }) => {
                if (statusMsg === SUCCESS_PURCHASE_STATUS || statusMsg === TIMEOUT_PURCHASE_STATUS) {
                    setHideEnroll(false);
                    setPurchase(false);

                    return;
                }

                if (!inQueue && showPopup)
                    getUserReserves();

                setPurchase(!inQueue);
                setEnrolling(inQueue);
                setInQueue(inQueue);
                setQueueNumber(queueNumber);
                setFinishedUsersCounter(finishedUsersCounter);
                setQueueDataShowed(true);
                setUserQueueTimestamp(queueTimestamp);

                if (!queueDataShowed)
                    showQueueData(inQueue);
            })
            .catch((e) => toast.error(e));
    }

    const getUserReserves = () => {
        axios.get(`${MINTED_NFT_ENDPOINT}/user-reserves?address=${userAddress}`)
            .then(({ data }) => setReserved(data))
            .catch((e) => toast.error(e));
    }

    const handleEnroll = () => {
        setEnrolling(true);

        axios.post(QUEUE_ENDPOINT, { address: userAddress })
            .then(() => getUserFromQueue())
            .catch((e) => toast.error(e));
    }

    const getFilterPacksToBuy = () => {
        const { pack1ToBuy, pack2ToBuy, pack3ToBuy } = packsToBuy;

        const filteredPacks1ToBuy = reserved
            .filter(({ packTemplateId }) => packTemplateId === PACK_1_ITEM_TEMPLATE_ID)
            .slice(0, pack1ToBuy);

        const filteredPacks2ToBuy = reserved
            .filter(({ packTemplateId }) => packTemplateId === PACK_2_ITEM_TEMPLATE_ID)
            .slice(0, pack2ToBuy);

        const filteredPacks3ToBuy = reserved
            .filter(({ packTemplateId }) => packTemplateId === PACK_3_ITEM_TEMPLATE_ID)
            .slice(0, pack3ToBuy);

        return [...filteredPacks1ToBuy, ...filteredPacks2ToBuy, ...filteredPacks3ToBuy];
    }

    const handlePurchase = async () => {
        try {
            setPurchasing(true);

            const filterPacksToBuy = getFilterPacksToBuy();

            const saleItemsIdsToBuy = filterPacksToBuy.map(({ id }) => parseInt(id));

            if (!saleItemsIdsToBuy || !saleItemsIdsToBuy.length)
                return toast.warning('You have no items left or 5 minutes purchase window closed');

            await buyMarketItem({
                saleItemIDs: saleItemsIdsToBuy,
                marketCollectionAddress: DARKCOUNTRY_MARKET_ADDRESS
            });

            setReserved(reserved.filter(({ id }) => !saleItemsIdsToBuy.includes(id)));
            showCongratulationModal(true);
            successPurchase();
        } catch (e) {
            toast.error(e);
            console.log(e);
        } finally {
            setPurchasing(false);
        }
    }

    const handleSaleStart = () => setSaleStarted(true);

    return (
      <div className="purchase-wrapper">
          <Countdown
              date={new Date(Date.parse(SALE_START_TIME))}
              handleSaleStart={handleSaleStart}
          />

          { userAddress &&
              <div className="purchase-total-container">
                  <p>Your total order is: { total } FLOW</p>
                  { purchase && !purchasing && !hideEnroll &&
                      <CustomButton
                          text={'Purchase'}
                          onClick={handlePurchase}
                          disabled={total === 0 || inQueue}
                      />
                  }
                  { purchasing &&
                    <p className="processing">Processing</p>
                  }
                  { saleStarted && !purchase && !purchasing && !enrolling && !hideEnroll &&
                      <CustomButton
                          text={'Enroll'}
                          onClick={handleEnroll}
                          disabled={!saleStarted}
                      />
                  }
              </div>
          }

          { queueData &&
              <QueueDataModal
                  visible={queueData}
                  onClose={() => showQueueData(false)}
                  queueNumber={queueNumber}
                  finishedUsersCounter={finishedUsersCounter}
              />
          }

          { congratulationModal &&
              <PurchaseCongratulationModal
                  visible={congratulationModal}
                  onClose={() => showCongratulationModal(false)}
              />
          }
      </div>
    );
}
