import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";

import './Market.scss';

import MarketPacks from "../../components/Market/MarketPacks/MarketPacks";
import Purchase from "../../components/Market/Purchase/Purchase";
import MarketFooter from "../../components/Market/MarketFooter/MarketFooter";
//import WhitelistVerificationModal from "../../modals/WhitelistVerificationModal/WhitelistVerificationModal";

import {
    PACK_1_ID,
    PACK_2_ID,
    PACK_3_ID,
    PACK_1_ITEM_TEMPLATE_ID,
    PACK_2_ITEM_TEMPLATE_ID,
    PACK_3_ITEM_TEMPLATE_ID
} from "../../constants/pack.constants";
import { WHITE_LIST_ENDPOINT, PURCHASE_API } from "../../constants/aws.constants";

export default function Market() {
    const [purchaseBlock, showPurchaseBlock] = useState(true);
    const [soldOut, setSoldOut] = useState(false);
    //const [whiteListModal, showWhiteListModal] = useState(false);
    const [totalOrder, setTotalOrder] = useState(0);
    const [packsToBuy, setPacksToBuy] = useState({
        pack1ToBuy: 0,
        pack2ToBuy: 0,
        pack3ToBuy: 0
    });
    const [resetCounters, setResetCounters] = useState(false);
    const [userPack1Reserve, setUserPack1Reserve] = useState(0);
    const [userPack2Reserve, setUserPack2Reserve] = useState(0);
    const [userPack3Reserve, setUserPack3Reserve] = useState(0);

    const user = useSelector(({ auth }) => auth.auth);

    useEffect(() => {
        const checkUserInWhiteList = (address) => {
            axios.get(`${WHITE_LIST_ENDPOINT}?address=${address}`)
                .then(async ({ data }) => {
                    // showPurchaseBlock(!!data);

                    // if (!data)
                    //     return showWhiteListModal(true);

                    const userPurchases = await getUserPurchases({ address });

                    combineReserveWithPurchases({
                        pack1Reserve: data.pack1Reserve,
                        pack2Reserve: data.pack2Reserve,
                        pack3Reserve: data.pack3Reserve,
                        userPurchases
                    });
                })
                .catch((e) => toast.error(e));
        }

        const combineReserveWithPurchases = ({ pack1Reserve, pack2Reserve, pack3Reserve, userPurchases }) => {
            let pack1PurchaseCounter = 0;
            let pack2PurchaseCounter = 0;
            let pack3PurchaseCounter = 0;

            userPurchases.forEach(({ packId }) => {
                const packTemplateIdByPackId = getPackTemplateIdByPackId(packId);

                switch (packTemplateIdByPackId) {
                    case PACK_1_ITEM_TEMPLATE_ID:
                        pack1PurchaseCounter++;
                        break;

                    case PACK_2_ITEM_TEMPLATE_ID:
                        pack2PurchaseCounter++;
                        break;

                    case PACK_3_ITEM_TEMPLATE_ID:
                        pack3PurchaseCounter++;
                        break;
                }
            });

            setUserPack1Reserve(pack1Reserve - pack1PurchaseCounter);
            setUserPack2Reserve(pack2Reserve - pack2PurchaseCounter);
            setUserPack3Reserve(pack3Reserve - pack3PurchaseCounter);
        }

        if (user.loggedIn)
            checkUserInWhiteList(user.addr);
    }, [user]);

    const getUserPurchases = async ({ address }) => {
        const { data } = await axios.get(`${PURCHASE_API}/user?address=${address}`);

        return data;
    };

    const getPackTemplateIdByPackId = (packId) => {
        if (packId >= 0 && packId < 2100)
            return PACK_1_ITEM_TEMPLATE_ID;

        if (packId >= 2100 && packId < 2400)
            return PACK_2_ITEM_TEMPLATE_ID;

        if (packId >= 2400 && packId < 2500)
            return PACK_3_ITEM_TEMPLATE_ID;
    }

    // const closeWhiteListVerificationModal = () => showWhiteListModal(false);

    const successPurchase = () => setResetCounters(!resetCounters);

    const handleUserReserveChange = (totalBought, packNumber) => {
        switch (packNumber) {
            case PACK_1_ID:
                return setUserPack1Reserve(userPack1Reserve - totalBought);
            case PACK_2_ID:
                return setUserPack2Reserve(userPack2Reserve - totalBought);
            case PACK_3_ID:
                return setUserPack3Reserve(userPack3Reserve - totalBought);
        }
    }

    useEffect(() => {
        if (soldOut)
            showPurchaseBlock(false);
    }, [soldOut, purchaseBlock]);

    const handleSoldOut = () => setSoldOut(true);

    return (
        <div className="market-wrapper">
            <div className="market-content">
                { soldOut && <p className="sold-out">SOLD OUT!</p> }

                <MarketPacks
                    userPack1Reserve={userPack1Reserve}
                    userPack2Reserve={userPack2Reserve}
                    userPack3Reserve={userPack3Reserve}
                    showCountButtons={true}
                    setTotalOrder={setTotalOrder}
                    resetCounters={resetCounters}
                    setUserReserve={handleUserReserveChange}
                    handleSoldOut={handleSoldOut}
                    soldOut={soldOut}
                    packsToBuy={packsToBuy}
                    setPacksToBuy={setPacksToBuy}
                />

                {purchaseBlock &&
                    <Purchase
                        total={totalOrder}
                        successPurchase={successPurchase}
                        packsToBuy={packsToBuy}
                    />
                }
            </div>
            {/*<WhitelistVerificationModal*/}
            {/*    visible={whiteListModal}*/}
            {/*    onClose={closeWhiteListVerificationModal}*/}
            {/*/>*/}
            <MarketFooter />
        </div>
    )
}
