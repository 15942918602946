import collectible from "../../../resourses/Flow_landing_assets/collectible_image.png";
import builder from "../../../resourses/Flow_landing_assets/city_builder_image.png";
import mmo from "../../../resourses/Flow_landing_assets/mmo_rpg_map_image.png";
import alive from "../../../resourses/Flow_landing_assets/alivegameicon.png";
import community from "../../../resourses/Flow_landing_assets/pvpgameicon.png";
import multichain from "../../../resourses/Flow_landing_assets/blockchaingameicon.png";

import "./About.scss";

import RandomSlide from "../AnimationComponents/RandomSlide/RandomSlide";

function About() {
  return (
    <div className={"about"} id={"about"}>
      <h2>WHAT IS THE DARK COUNTRY?</h2>
      <p className={"info-wrapper"}>
        Dark Country is well-known Trading Card Game - project built on
        multichain framework to provide excellent gaming experience with a more
        than 2-years history. Over this time we've created and launched a
        playable version of the game, developed a community of dedicated gamers
        and asset-owners, launched tournaments and permanently work on new game
        modes, assets, mechanics and more.
      </p>
      <div>
        <span id={"multilayer-text"}>MULTILAYER GAMEPLAY</span>
      </div>
      <div className={"multilayer-container"}>
        {/*<div>*/}
        <RandomSlide>
          <img src={collectible} alt="" />
          <p>Collectible card game</p>
        </RandomSlide>
        {/*</div>*/}
        {/*<div>*/}
        <RandomSlide>
          <img src={builder} alt="" />
          <p>City Builder</p>
        </RandomSlide>

        {/*</div>*/}
        {/*<div>*/}
        <RandomSlide>
          <img src={mmo} alt="" />
          <p>MMO RPG World Map</p>
          {/*</div>*/}
        </RandomSlide>
      </div>
      <div className={"text-end"}>
        <span id={"projectLove-text"}>THE PROJECT WE LOVE TO GROW!</span>
      </div>
      <div className={"game-info-wrapper"}>
        {/*<div>*/}
        <RandomSlide>
          <img src={alive} alt="" />
          <h3>The game is (A)LIVE!</h3>
          <p>Alpha version launched in September 2020</p>
        </RandomSlide>

        {/*</div>*/}
        {/*<div>*/}
        <RandomSlide>
          <img src={community} alt="" />
          <h3>Active community</h3>
          <p>
            Over <span>50 000</span> PvP rounds played already
          </p>
        </RandomSlide>

        {/*</div>*/}
        {/*<div>*/}
        <RandomSlide>
          <img src={multichain} alt="" />
          <h3>1st Multichain TCG</h3>
          <p>A framework to support several blockchains</p>
        </RandomSlide>

        {/*</div>*/}
      </div>
    </div>
  );
}

export default About;
