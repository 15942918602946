import * as fcl from "@blocto/fcl";
import { tx } from "../util/tx";

import {
    DARKCOUNTRY_ADDRESS,
    NON_FUNGIBLE_TOKEN_ADDRESS
} from "../../constants/contract.constants";

const CODE = `
    import NonFungibleToken from ${NON_FUNGIBLE_TOKEN_ADDRESS} 
    import DarkCountry from ${DARKCOUNTRY_ADDRESS} 
    
    // This transaction transfers a NFT from one account to another.

    transaction(recipient: Address, withdrawID: UInt64) {
        prepare(signer: AuthAccount) {
    
            // get the recipients public account object
            let recipient = getAccount(recipient)
    
            // borrow a reference to the signer's NFT collection
            let collectionRef = signer.borrow<&DarkCountry.Collection>(from: DarkCountry.CollectionStoragePath)
                ?? panic("Could not borrow a reference to the owner's collection")
    
            // borrow a public reference to the receivers collection
            let depositRef = recipient.getCapability(DarkCountry.CollectionPublicPath)!.borrow<&{DarkCountry.DarkCountryCollectionPublic}>()!
    
            // withdraw the NFT from the owner's collection
            let nft <- collectionRef.withdraw(withdrawID: withdrawID)
    
            // Deposit the NFT in the recipient's collection
            depositRef.deposit(token: <-nft)
        }
    }
`;

export function transferPack({ recipient, packId }) {
    console.log({ recipient, packId });

    return tx(
        {
            cadence: CODE,
            args: (arg, t) => [
                arg(recipient, t.Address),
                arg(packId, t.UInt64),
              ],
              proposer: fcl.currentUser,
              payer: fcl.currentUser,
              authorizations: [fcl.currentUser],
              limit: 1000,
        }
    )
}
