export const DARKCOUNTRY_ADDRESS = '0xc8c340cebd11f690';
export const DARKCOUNTRY_MARKET_ADDRESS = '0xc8c340cebd11f690';
export const FUNGIBLE_TOKEN_ADDRESS = '0xf233dcee88fe0abe';
export const FLOW_TOKEN_ADDRESS = '0x1654653399040a61';
export const NON_FUNGIBLE_TOKEN_ADDRESS = '0x1d7e57aa55817448';
export const ADDRESS_FOR_TRANSFER = '0x837f59ba8ce596de';

//TESTNET
// export const DARKCOUNTRY_ADDRESS = '0x52da36f421cf3630';
// export const DARKCOUNTRY_MARKET_ADDRESS = '0x52da36f421cf3630';
// export const FUNGIBLE_TOKEN_ADDRESS = '0x9a0766d93b6608b7';
// export const FLOW_TOKEN_ADDRESS = '0x7e60df042a9c0868';
// export const NON_FUNGIBLE_TOKEN_ADDRESS = '0x631e88ae7f1d7c20';
//export const ADDRESS_FOR_TRANSFER = '0xf4f16a7d686434f7';
