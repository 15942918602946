import React from "react";

import './Authors.scss';

export default function Authors() {
    return (
        <div className={"authors"}>
            <p className={"dark-grey"}>℗ Immortal Games 2019-2021</p>
            <p className={"dark-grey"}>© Dark Country.io</p>
        </div>
    );
}
