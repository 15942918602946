import React from "react";

import Rodal from "rodal";
import "rodal/lib/rodal.css";

import './QueueDataModal.scss';

export default function QueueDataModal({ visible, onClose, queueNumber, finishedUsersCounter }) {
    const usersInQueueAheadOfYou = queueNumber - finishedUsersCounter - 1;

    return (
        <Rodal
            visible={visible}
            onClose={onClose}
            showCloseButton={true}
            className="queue-data"
            animation="slideUp"
            duration={800}
            closeOnEsc={true}
        >
            <div className="queue-data-modal-wrapper">
                { +queueNumber === '-1'
                    ? <>
                        <p><span>You are in waiting room</span></p>
                    </>
                    : <>
                        <p>Your queue number is</p>
                        <p className="queue-number">{ queueNumber }</p>
                        <p>Number of users in queue ahead of you: <span>{ usersInQueueAheadOfYou > 0 ? usersInQueueAheadOfYou : 0 }</span></p>
                    </>
                }
                <p>When it is your turn, you will have <span>5 minutes</span> to make a purchase</p>
                <p>Wait until pop up disappears - then you can make a purchase</p>
            </div>
        </Rodal>
    );
}
