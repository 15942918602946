import React from "react";

import Rodal from "rodal";
import "rodal/lib/rodal.css";

import './WhitelistedAddressModal.scss';

export default function WhitelistedAddressModal({ visible, onClose, address, pack1Reserve, pack2Reserve, pack3Reserve }) {
    return (
        <Rodal
            visible={visible}
            onClose={onClose}
            showCloseButton={true}
            className="whitelisted-address"
            animation="slideUp"
            duration={800}
            closeOnEsc={true}
        >
            <div className="whitelisted-address-modal-wrapper">
                <p>Your account: { address } is whitelisted</p>
                <p>Rancho pack: <span>{ pack1Reserve }</span>, Mayor's pack: <span>{ pack2Reserve }</span>, Governor's Pack: <span>{ pack3Reserve }</span></p>
            </div>
        </Rodal>
    );
}
