import React, { useEffect, useState } from "react";

import bgimage from "../../resourses/Flow_landing_assets/bgFLOW3.jpg";
import startImage from "../../resourses/Flow_landing_assets/world_map_image_main.png";

import SpinnerLoader from "../../components/Landing/SpinnerLoader/SpinnerLoader";
import LandingContainer from "../../components/Landing/LandingContainer/LandingContainer";

function LandingWrapper() {
    const [bgLoaded, setLoad] = useState(false);
    const [src, setSrc] = useState("");
    const [imageLoaded, setImageLoaded] = useState(false)

    useEffect(() => {
        const bg = new Image();
        bg.src = bgimage;

        const img = new Image();
        img.src = startImage;

        img.onload = function () {
            setImageLoaded(true)
        }

        bg.onload = function () {
            setTimeout(() => {
                setSrc(bgimage);
            }, 2000)

            setTimeout(() => {
                setLoad(true);
            }, 4000);
        };
    }, []);

    return (
        <div className="App">
            {!bgLoaded ? <SpinnerLoader /> : ""}

            {src && imageLoaded ? <LandingContainer bg={src} visibility={bgLoaded} /> : ""}
            {/*<LandingContainer bg={src} visibility={true} />*/}
        </div>
    );
}

export default LandingWrapper;
