import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import Header from "./components/Header/Header";
import LandingWrapper from "./containers/LandingWrapper/LandingWrapper";
import Market from "./containers/Market/Market";
import MyItems from "./containers/MyItems/MyItems";
import LandUnpacker from "./containers/LandUnpacker/LandUnpacker";

import "./App.css";

export const routes = [];

function App() {
  return (
      <>
        <Header />

        <Switch>
            <Route path="/" exact component={LandingWrapper} />
            <Route path="/market" exact component={Market} />
            <Route path="/my-items" exact component={MyItems} />

            <Route path="/unpacker/land" exact component={LandUnpacker} />

            <Redirect to="/" />
        </Switch>

        <ToastContainer
            position="bottom-left"
            autoClose={4000}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss
            draggable
        />
      </>
  );
}

export default App;
