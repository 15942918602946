import { LazyLoadComponent } from "react-lazy-load-image-component";

import "./LandDescription.scss";

import zones from "../../../resourses/Flow_landing_assets/zone_map_1.png";

function LandDescription() {
    return (
        <div className={"land-description"}>
            <h2>LAND PLOTS RARITY & FARMING RATE</h2>
            <div className={"description-text"}>

                <p>
                    The Flow Land continent has a 100x100 dimension with a total of 10 000 land pieces. Some of them are
                    reserved for Landlords and their cities, the others - for special game zones and activities. For the
                    Genesis Dark Country Land Sale on Flow we will offer 4000 land plots. Each land lies within a
                    certain zone of the map: Mythical, Legendary, Epic, Rare and Common, and has an
                    influencing parameter depending on the position
                    in relation to the map-center. The influence parameter impacts your
                    land's farming capabilities and is calculated depending on the zone
                    rarity and a position relative to the central point.
                </p>
                <p>
                    At the center of each Continent Map lies a Dark Portal - the [0;0]
                    point, which is a prime source of in-game currency - the Shadow
                    Dimes, and a place to go on quests and farming of other resources.
                </p>
                <p>
                    Every week Dark Portal generates a certain amount of Shadow Dimes
                    and other resources that are distributed among the Landowners
                    according to their Landlord's Farming Rate & influence
                </p>
            </div>
            <LazyLoadComponent threshold={1000}>
                <div className={"map-zones"}>
                    <div className={"image-wrapper"}>
                        <img src={zones} alt=""/>
                    </div>
                    <div className={"land-classes"}>
                        <div className={"single-land"}>
                            <h3 className={'common-title'}>Common lands</h3>
                            <p className={"single-land-description"}>
                                The most widespread land zone that occupies 75% of the Continent territory and has 3140
                                land plots in total.
                            </p>
                            <p className={"farming-rate"}>
                                Farming rate: <span className={"bold"}>Normal</span>
                            </p>
                        </div>

                        <div className={"single-land"}>
                            <h3 className={'rare-title'}>Rare lands</h3>
                            <p className={"single-land-description"}>
                                The rare lands zone lies closer to the center of the map and takes 16% of the whole
                                Continent with 600 land plots.
                            </p>
                            <p className={"farming-rate"}>
                                Farming rate: <span className={"bold"}>Fast</span>
                            </p>
                        </div>

                        <div className={"single-land"}>
                            <h3 className={'epic-title'}>Epic lands</h3>
                            <p className={"single-land-description"}>
                                There are 200 Epic land plots on the continent and the epic land zone occupies 8% of the
                                total territory.
                            </p>
                            <p className={"farming-rate"}>
                                Farming rate: <span className={"bold"}>Very Fast</span>
                            </p>
                        </div>

                        <div className={"single-land"}>
                            <h3 className={'legendary-title'}>Legendary lands</h3>
                            <p className={"single-land-description"}>
                                There are only 50 Legendary land plots in the center of the continent map.
                            </p>
                            <p className={"farming-rate"}>
                                Farming rate: <span className={"bold"}>Super Fast</span>
                            </p>
                        </div>

                        <div className={"single-land"}>
                            <h3 className={'mythical-title'}>Mythical lands</h3>
                            <p className={"single-land-description"}>
                                Just 10 Mythical land plots are available right next to the Dark Portal. These are the
                                ones with the highest farming rates!
                            </p>
                            <p className={"farming-rate"}>
                                Farming rate: <span className={"bold"}>Ultra Fast</span>
                            </p>
                        </div>
                    </div>
                </div>
            </LazyLoadComponent>
        </div>
    );
}

export default LandDescription;
