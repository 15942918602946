export const PACK_1_ID = 1;
export const PACK_1_NAME = 'Rancho Pack';
export const PACK_1_AMOUNT = 2100;
export const PACK_1_PRICE = 7;
export const PACK_1_LAND_PLOTS = 1;
export const PACK_1_CARDS = 5;
export const PACK_1_ITEM_TEMPLATE_ID = 2;

export const PACK_2_ID = 2;
export const PACK_2_NAME = 'Mayor\'s pack';
export const PACK_2_AMOUNT = 300;
export const PACK_2_PRICE = 20;
export const PACK_2_LAND_PLOTS = 3;
export const PACK_2_CARDS = 15;
export const PACK_2_ITEM_TEMPLATE_ID = 3;

export const PACK_3_ID = 3;
export const PACK_3_NAME = 'Governor\'s Pack';
export const PACK_3_AMOUNT = 100;
export const PACK_3_PRICE = 60;
export const PACK_3_LAND_PLOTS = 10;
export const PACK_3_CARDS = 50;
export const PACK_3_ITEM_TEMPLATE_ID = 4;

export const BONUS_PACK_TEMPLATE_ID = 5;
