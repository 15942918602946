import "./Art.scss";

import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/scss/alice-carousel.scss";

import mustang from "../../../resourses/Flow_landing_assets/mustang.png";
import bazooka from "../../../resourses/Flow_landing_assets/BazookaZombie.png";
import mlion from "../../../resourses/Flow_landing_assets/MLion.png";
import piasa from "../../../resourses/Flow_landing_assets/Piasa.png";
import preacher from "../../../resourses/Flow_landing_assets/preacher.png";
import silvia from "../../../resourses/Flow_landing_assets/silvia.png";
import soul from "../../../resourses/Flow_landing_assets/Soul.png";
import woman from "../../../resourses/Flow_landing_assets/womangoat.png";
import general from "../../../resourses/Flow_landing_assets/General-ghost.png"
import navajo from "../../../resourses/Flow_landing_assets/NavajoWitch.png"
import sexy from "../../../resourses/Flow_landing_assets/Sexy.png"
import skmage from "../../../resourses/Flow_landing_assets/Sk-mage.png"
import wolf from "../../../resourses/Flow_landing_assets/wolf.png"
import yeti from "../../../resourses/Flow_landing_assets/Yeti.png"


import { LazyLoadComponent } from "react-lazy-load-image-component";


function Art() {
  const handleDragStart = (e) => e.preventDefault();

  const items = [
    <img src={woman} onDragStart={handleDragStart} alt="" />,
    <img src={soul} onDragStart={handleDragStart} alt="" />,
    <img src={bazooka} onDragStart={handleDragStart} alt="" />,
    <img src={preacher} onDragStart={handleDragStart} alt="" />,
    <img src={piasa} onDragStart={handleDragStart} alt="" />,
    <img src={mustang} onDragStart={handleDragStart} alt="" />,
    <img src={silvia} onDragStart={handleDragStart} alt="" />,
    <img src={mlion} onDragStart={handleDragStart} alt="" />,
    <img src={general} onDragStart={handleDragStart} alt="" />,
    <img src={navajo} onDragStart={handleDragStart} alt="" />,
    <img src={sexy} onDragStart={handleDragStart} alt="" />,
    <img src={skmage} onDragStart={handleDragStart} alt="" />,
    <img src={wolf} onDragStart={handleDragStart} alt="" />,
    <img src={yeti} onDragStart={handleDragStart} alt="" />,

  ];
  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 5 },
  };

  return (
    <>
      <div className={"art"}>
        <h2>THE ART OF DARK COUNTRY</h2>
        <p>
          Artwork is something that makes Dark Country an outstanding project
          among the mass-market blockchain gaming projects. We strive for the
          highest quality and visual aesthetics of every single piece of asset
          we create
        </p>
      <LazyLoadComponent threshold={1000}>
        <div id={'art-carousel'}>
          <AliceCarousel mouseTracking items={items} responsive={responsive} infinite={true} autoPlay={true} autoPlayStrategy={'action'} autoPlayInterval={1500}/>
        </div>
      </LazyLoadComponent>
      </div>
    </>
  );
}

export default Art;
