import "./Videos.scss";

function Videos() {
  return (
    <div className={"videos"} id={"promo"}>
        <h2>THE DARK COUNTRY VIDEOS</h2>
        <p>
          We are proud of how our Dark Country looks, feels and is played. Don't
          be shy - take a close look at what we've got!
        </p>
        <div className={"main-video"}>
          <iframe title={'video'} src={"https://www.youtube.com/embed/KTaAeH1kwg0"}  />
        </div>
        <div className={"videos-wrapper"}>
          <iframe title={'video'} src={"https://www.youtube.com/embed/Bd4Hrbacr58"} />
          <iframe title={'video'} src={"https://www.youtube.com/embed/SueG3ZSbMwY"} />
          <iframe title={'video'} src={"https://www.youtube.com/embed/5ZPrmQsGmMo"} />
        </div>
    </div>
  );
}

export default Videos;
