import React, { useState, useEffect } from 'react';

import { Button } from '@material-ui/core';

import './CardSlider.scss';

export default function CardSlider({ lands, onDoneClicked }) {
    const [index, setIndex] = useState(0);

    useEffect(() => setIndex(0), [lands]);

    const onNextClicked = () => {
        removeAnimationClassFromCard();

        setIndex(index + 1);
    }

    const removeAnimationClassFromCard = () => {
        document.getElementById('card-shown').classList.remove('animated-card--appear');
    }

    const addAnimationClassToCard = () => {
        document.getElementById('card-shown').classList.add('animated-card--appear');
    }

    return (
        <div className="card-slider">
            <div className="card-slider_card">
                { lands[index].videoLink
                    ? <video
                        id="card-shown"
                        className="card-slider_card_image animated-card--appear"
                        onLoad={() => addAnimationClassToCard()}
                        src={lands[index].videoLink}
                        loop={true}
                        autoPlay={true}
                    />
                    : <img id="card-shown"
                           className="card-slider_card_image animated-card--appear"
                           onLoad={() => addAnimationClassToCard()}
                           src={lands[index].imageLink}
                           alt={lands[index].name}
                    />
                }
            </div>
            <div className="card-slider_controls">
                { index < lands.length - 1
                    ? <Button onClick={onNextClicked} className="btn--spec-filled">
                        <p>Next</p>
                    </Button>
                    : <Button onClick={onDoneClicked} className="btn--spec-filled">
                        <p>Done</p>
                    </Button>
                }
            </div>
            <div className="card-slider_details">
                { index + 1 } of { lands.length }
            </div>
        </div>
    )
}
