import React from "react";

import StartBlock from "../StartBlock/StartBlock";
import Packs from "../Packs/Packs";
import WhiteList from "../WhiteList/WhiteList";
import Lands from "../Lands/Lands";
import About from "../About/About";
import Vampires from "../Vampires/Vampires";
import Videos from "../Videos/Videos";
import Press from "../Press/Press";
import Unpacking from "../Unpacking/Unpacking";
import Reviews from "../Reviews/Reviews";
import Partners from "../Partners/Partners";
import Footer from "../Footer/Footer";
import RoadMap from "../RoadMap/RoadMap";
import Art from "../Art/Art";
import LandDescription from "../LandDescription/LandDescription";

import "./LandingContainer.scss";

function LandingContainer({bg, visibility}) {

    return (
        <div
            className={`landing-wrapper ${!visibility ? 'hidden': ''} ${!visibility ? 'landing-invisible': ''}`}
            style={{
                "backgroundImage": `url(${bg})`,
            }}
        >

            {/*<Header />*/}
            <StartBlock/>
            <Packs/>
            <WhiteList/>
            <About/>

            <Lands/>
            <LandDescription/>
            <Vampires/>

            <Unpacking/>
            <RoadMap/>
            <Videos/>

            <Art/>
            <Press/>
            <Reviews/>

            <Partners/>
            {/*<FreeVirgin/>*/}
            <Footer/>
        </div>
    );
}

export default LandingContainer;
