import React, { useState } from "react";
//import axios from "axios";
//import { toast } from "react-toastify";

//import * as fcl from "@blocto/fcl";

import bonusPack from "../../../resourses/3d/Bonus_60F.webm";

import "./WhiteList.scss";

//import Gesture from "../AnimationComponents/Gesture/Gesture";
import WhitelistedAddressModal from "../../../modals/WhitelistedAddressModal/WhitelistedAddressModal";

//import { WHITE_LIST_ENDPOINT } from "../../../constants/aws.constants";

function WhiteList() {
    const [userInWhitelist, setUserInWhitelist] = useState(false);
    const [whitelistedAddress, setWhitelistedAddress] = useState('');
    const [pack1Reserve, setPack1Reserve] = useState(0);
    const [pack2Reserve, setPack2Reserve] = useState(0);
    const [pack3Reserve, setPack3Reserve] = useState(0);
    //const [verify, setVerify] = useState(false);

    // useEffect(() => {
    //     fcl.currentUser().subscribe(user => {
    //         if (user.loggedIn && !verify) {
    //             setVerify(true);
    //             verifyAddress(user.addr);
    //         }
    //     });
    // }, []);

    //const signIn = () => fcl.authenticate();

    // const verifyAddress = (address) => {
    //     axios.get(`${WHITE_LIST_ENDPOINT}?address=${address}`)
    //         .then(({ data }) => {
    //             if (!data) {
    //                 setUserInWhitelist(false);
    //                 return toast.warning('User is not whitelisted');
    //             }
    //
    //             setUserInWhitelist(true);
    //             setWhitelistedAddress(address);
    //             setPack1Reserve(data.pack1Reserve);
    //             setPack2Reserve(data.pack2Reserve);
    //             setPack3Reserve(data.pack3Reserve);
    //         })
    //         .catch((e) => toast.error(e))
    //         .finally(() => sighOut());
    // }

    // const sighOut = () => {
    //     fcl.unauthenticate();
    //
    //     //setVerify(false);
    // }

    const closeModal = () => setUserInWhitelist(false);

    return (
        <div className={"whitelist"} id={"whitelist"}>
            <p className={"grey"}>JOIN THE DARK COUNTRY PRE-SALE WHITELIST</p>
            <p className={"red"}>
                BE THE FIRST TO BUY DARK COUNTRY LANDS AND GET AN EXCLUSIVE BONUS!
            </p>

            <div className={"info-container"}>
                <div className={'bonus-wrapper'}>
                    <video autoPlay={true} loop={true} muted={true} src={bonusPack}>
                    </video>
                    <p>Bonus-pack</p>
                </div>
                <div className={'info-wrapper'}>
                    <p>Only <span className={'bold'}>2500 packs</span> will be available in total, so we advise you to
                        secure your Land packs today!</p>
                    <p>Important: no land packs may be left for Main Sale after the Pre-Sale is over.</p>
                    <p><span className={'bold'}>Whitelist Rules:</span> To enter the Pre-Sale you need to have a valid
                        FLOW account with at least <span className={'bold'}>7 FLOW</span> (liquid or staked).
                        Before the launch of the Pre-Sale we will validate your account.
                    </p>
                    <p>Number of packs of the same type is limited by <span
                        className={'bold'}>5 per one account.</span> You can reserve a maximum of <span
                        className={'bold'}>15 packs.</span></p>
                    <p>The first <span className={'bold'}>300 buyers</span> from whitelist will get our special bonus
                        card pack with <span className={'bold'}>5 random</span> Dark Country cards. Join NOW!</p>
                    {/*<p>*/}
                    {/*    We welcome you to join our Dark Country Land Pre-Sale Whitelist by*/}
                    {/*    filling out a simple form and secure your Land packs!*/}
                    {/*</p>*/}
                    {/*<p>All the whitelist <span className={'bold'}>buyers</span> will get our special bonus card pack*/}
                    {/*    with 5 random Dark Country*/}
                    {/*    cards.</p>*/}
                    {/*<p>Hit the button and sit in the first row!</p>*/}
                    {/*<div className={"button"}>*/}
                    {/*    <Gesture>*/}
                    {/*        /!*<a href={'https://form.typeform.com/to/MoTK8wAf'} target={'_blank'}>*!/*/}
                    {/*        /!*    <span className={'join-button'}>JOIN THE WHITELIST</span>*!/*/}
                    {/*        /!*</a>*!/*/}
                    {/*        <span*/}
                    {/*            className={'join-button'}*/}
                    {/*            onClick={() => signIn()}*/}
                    {/*        >VERIFY ADDRESS</span>*/}
                    {/*    </Gesture>*/}
                    {/*</div>*/}
                </div>
            </div>

            <WhitelistedAddressModal
                visible={!!userInWhitelist}
                onClose={closeModal}
                address={whitelistedAddress}
                pack1Reserve={pack1Reserve}
                pack2Reserve={pack2Reserve}
                pack3Reserve={pack3Reserve}
            />
        </div>
    );
}

export default WhiteList;
