import React, { useEffect, useState }  from "react";
import { Link, useLocation } from "react-router-dom";

import Auth from "../Auth/Auth";

import './Header.scss';

import dcLogo from "../../resourses/Flow_landing_assets/dcLOGO.png";

export default function Header() {
    const { pathname } = useLocation();

    const [visibility, setVisibility] = useState(false);

    useEffect(() => {
        const handleHeaderVisibility = () => {
            const pagesWithoutHeader = ['/', '/unpacker/land'];

            setVisibility(!!!pagesWithoutHeader.includes(pathname));
        };

        handleHeaderVisibility();
    }, [pathname]);

    return (
        <div className={`market-header-wrapper ${!visibility ? 'visibility-hidden' : ''}`}>
            <div className="market-header-content">
                <div className="market-header-left-container">
                    <div className="logos">
                        <Link to="/">
                            <img src={dcLogo} alt="dc-logo"/>
                        </Link>
                    </div>
                    <div className="market-menu">
                        <Link to="/market">{ 'Market' }</Link>
                        <Link to="/my-items">{ 'My Items' }</Link>
                        <Link to="/unpacker/land">{ 'Land unpacker' }</Link>
                    </div>
                </div>
                <Auth />
            </div>
        </div>
    );
}
