import "./Reviews.scss";
import willie from "../../../resourses/Flow_landing_assets/Willie_avatar.png";
import jo from "../../../resourses/Flow_landing_assets/Jo_avatar.png";
import kaiser from "../../../resourses/Flow_landing_assets/Kaiser_avatar.png";
import dogtown from "../../../resourses/Flow_landing_assets/Dogtown_avatar.png";

import { LazyLoadComponent } from "react-lazy-load-image-component";

function Reviews() {
  return (
    <div className={"reviews"}>
      <h2>What people say</h2>
      <p>
        Community is the core driving force of Dark Country that gives us the
        power to develop as a high-value project for both gamers and crypto-fans
        community
      </p>
      <LazyLoadComponent threshold={1000}>
      <div className={"reviews-wrapper"}>
        <div>
          <div className={"reviewer-wrapper"}>
            <img src={willie} alt="" />
            <span>Willie Theron</span>
          </div>
          <p>
            What I like about Dark Country is every turn a battle can turn
            around. Power shifts back and forth until one player is unable to
            answer. I had a match where we drew out decks and could only finish
            the battle when the losing player had no more cards to play. The
            most epic Collectible card match I have ever player, and I have
            played a lot.
          </p>
        </div>
        <div>
          <div className={"reviewer-wrapper"}>
            <img src={jo} alt="" />
            <span>Jo</span>
          </div>
          <p>
            Being a diehard MTG fan I couldn't wait for a worthy trading card
            game to come to the blockchain space. When I discovered Dark Country
            I new I had found it.
          </p>
        </div>
        <div>
          <div className={"reviewer-wrapper"}>
            <img src={kaiser} alt="" />
            <span>Kaiser Hunz</span>
          </div>
          <p>
            For someone who missed out on Magic TG in the 90's I feel like
            DarkCountry is giving me a second chance.
          </p>
        </div>
        <div>
          <div className={"reviewer-wrapper"}>
            <img src={dogtown} alt="" />
            <span>Dogtown</span>
          </div>
          <p>
            It’s been a great ride so far. Collecting, buying, trading, staking
            and playing. I can’t wait to see what’s next. The game truly changes
            when you own your assets.
          </p>
        </div>
      </div>
      </LazyLoadComponent>
    </div>
  );
}

export default Reviews;
